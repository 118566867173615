import wait from 'delay'
import PropTypes from 'prop-types'
import * as React from 'react'
import Decipher from '../Decipher/Decipher.js'

class Rotator extends React.PureComponent {
  state = {
    index: -1,
  }
  componentDidMount() {
    wait(this.props.startDelay).then(() => {
      this.setState({ index: 0 }, () => wait(this.props.delay).then(this.setNextWord))
    })
  }
  setNextWord = () => {
    this.setState((cs) => {
      const nextIndex = cs.index + 1
      if (this.props.words[nextIndex]) {
        return { index: nextIndex }
      }
      return {
        index: this.props.loop ? 0 : -1,
      }
    })
  }
  handleComplete = () => {
    if (this.state.index === -1 && !this.props.loop) {
      return
    }
    this.destroy()
    this.timer = window.setTimeout(this.setNextWord, this.props.delay)
  }
  destroy() {
    if (this.timer) {
      window.clearTimeout(this.timer)
      this.timer = null
    }
  }
  render() {
    if (!Array.isArray(this.props.words)) return null
    return (
      <Decipher
        fillBeforeDelay
        onComplete={this.handleComplete}
        message={this.props.words[this.state.index < 0 ? 0 : this.state.index]}
      />
    )
  }
}

Rotator.propTypes = {
  delay: PropTypes.number,
  startDelay: PropTypes.number,
  words: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
}

Rotator.defaultProps = {
  delay: 0,
  loop: true,
  startDelay: 0,
  onComplete: () => {},
}

export default Rotator
