import * as React from 'react'
import Seo from '../components/SEO/SEO.js'
import Stage from '../components/Stage/Stage.js'
import UnscriptedStage from '../components/UnscriptedStage/UnscriptedStage.js'

const IndexPage = () => {
  const [loaded, setLoaded] = React.useState(false)
  const done = () => setLoaded(true)

  React.useEffect(() => {
    require('webfontloader').load({
      google: { families: ['Space+Mono:400'] },
      timeout: 3000,
      classes: false,
      active: done,
      inactive: done,
    })
  })

  return loaded ? <Stage /> : <UnscriptedStage />
}

export default IndexPage

export const Head = () => <Seo />
