import cx from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'
import Touchable from '../Touchable/Touchable.js'
import * as styles from './IconButton.module.css'

const IconButton = ({ className, style, children, ...rest }) => (
  <Touchable style={style} className={cx('icon-button-pierre-cx', styles.root, className)} {...rest}>
    {children}
  </Touchable>
)

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default IconButton
