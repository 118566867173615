import getImageParticles from './getImageParticles.js'

const extractCircle = (percentage, { density, width, height, context }) => {
  const absoluteCenter = (width > height ? height : width) / 2
  const radius = Math.round(absoluteCenter * percentage)
  context.clearRect(0, 0, width, height)
  context.arc(width / 2, height / 2, radius < 300 ? 300 : radius, 0, 2 * Math.PI, false)
  context.lineWidth = percentage * 10
  context.stroke()
  return getImageParticles(context, { width, height, density })
}

export default extractCircle
