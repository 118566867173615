import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FaGithub, FaInstagram, FaLinkedin, FaSpotify, FaStackOverflow, FaTwitter } from 'react-icons/fa'
import IconButton from '../IconButton/IconButton.js'
import * as styles from './Social.module.css'

const Social = ({ className, style, children }) => {
  const [ready, setReady] = useState(false)

  const didMount = () => {
    window.requestAnimationFrame(() => setReady(true))
  }

  useEffect(didMount, [ready])

  return (
    <React.Fragment>
      <div className={cx(styles.root, className, { [styles.ready]: ready })} style={style}>
        <IconButton className={styles.icon} external to="https://github.com/PierreCholhot">
          <FaGithub />
        </IconButton>
        <IconButton className={styles.icon} external to="http://stackoverflow.com/users/1029952/pierre">
          <FaStackOverflow />
        </IconButton>
        <IconButton className={styles.icon} external to="https://linkedin.com/in/PierreCholhot">
          <FaLinkedin />
        </IconButton>
        <IconButton className={styles.icon} external to="https://twitter.com/PierreCholhot">
          <FaTwitter />
        </IconButton>
        <IconButton className={styles.icon} external to="https://instagram.com/PierreCholhot">
          <FaInstagram />
        </IconButton>
        <IconButton className={styles.icon} external to="https://open.spotify.com/user/isenburk">
          <FaSpotify />
        </IconButton>
      </div>
      <noscript className={styles.hidden}>
        <style
          dangerouslySetInnerHTML={{
            __html: `.${styles.icon} { transform: translate(0, 0)!important; opacity: 1!important; }`,
          }}
        />
      </noscript>
    </React.Fragment>
  )
}

Social.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Social
