import shuffle from 'array-shuffle'
import PropTypes from 'prop-types'
import * as React from 'react'

import animate from '../../utils/animate.js'
import arrayMask from '../../utils/arrayMask.js'

const arrayify = (text) => text.split('')

class Decipher extends React.PureComponent {
  state = {
    done: false,
    started: false,
    text: [],
  }

  componentDidMount() {
    this.initializeState()
  }

  componentDidUpdate(oldProps) {
    const newMessage = oldProps.message !== this.props.message
    const newWhen = oldProps.when !== this.props.when

    if (newMessage || newWhen) {
      this.initializeState()
    }
  }

  initializeState() {
    if (!this.props.when) return
    const text = arrayMask(arrayify(this.props.message))
    this.setState({ text }, this.decypher)
  }

  decypher() {
    const { delay } = this.props

    const duration = 30 * this.state.text.length
    const indexes = this.state.text.map((l, i) => i)
    const randomlySortedIndexes = shuffle(indexes)

    const onAnimationFrame = (progress) => {
      const nextIndex = Math.floor(progress * this.state.text.length)
      const nextActualIndex = randomlySortedIndexes[nextIndex]

      this.setState((cs) => {
        const newText = [...cs.text]
        newText[nextActualIndex] = this.props.message[nextActualIndex]
        return { started: true, text: newText }
      })
    }

    animate({ delay, duration, onAnimationFrame }).then(() => {
      this.setState({ done: true, text: arrayify(this.props.message) }, this.props.onComplete)
    })
  }

  wrap(children) {
    const Component = this.props.wrapper
    return Component ? <Component>{children}</Component> : children
  }

  renderContent() {
    if (!(Array.isArray(this.state.text) && this.state.text.length)) return null
    if (!this.state.started && !this.props.fillBeforeDelay) return null
    if (!this.props.when) return null
    if (this.state.done && this.props.finalMessage) return this.wrap(this.props.finalMessage)
    return this.wrap(this.state.text)
  }

  render() {
    return (
      <React.Fragment>
        <noscript>{this.wrap(this.props.noScriptsMessage ? this.props.noScriptsMessage : this.props.message)}</noscript>
        {this.renderContent()}
      </React.Fragment>
    )
  }
}

Decipher.propTypes = {
  wrapper: PropTypes.any,
  noScriptsMessage: PropTypes.any,
  when: PropTypes.bool,
  finalMessage: PropTypes.any,
  fillBeforeDelay: PropTypes.bool,
  onComplete: PropTypes.func,
  delay: PropTypes.number,
  message: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

Decipher.defaultProps = {
  wrapper: null,
  when: true,
  delay: 0,
  fillBeforeDelay: false,
  onComplete: () => {},
}

export default Decipher
