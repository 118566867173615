import { customProperties } from '../variables.js'
import getImageParticles from './getImageParticles.js'

const extractText = (text, { density, width, height, isMobile, context }) => {
  const fontSize = Math.round((width * (isMobile ? 15 : 5)) / 100)
  context.clearRect(0, 0, width, height)
  context.font = [fontSize, 'px ', customProperties['--fontfamily']].join('')
  const textWidth = Math.round(context.measureText(text).width)
  context.fillText(text, width / 2 - textWidth / 2, height / 2 + fontSize / 4)
  return getImageParticles(context, { width, height, density })
}

export default extractText
