function Particle(mx, my, fx, fy) {
  this.x = mx / 2
  this.y = my / 2
  this.mx = mx
  this.my = my
  this.fx = fx
  this.fy = fy
  this.vx = Math.random() - 0.5
  this.vy = Math.random() - 0.5
  this.radius = Math.floor(Math.random() * 22) + 8
  this.acceleration = 0.95
  this.linkedParticleIndex = false
}

Particle.prototype.roamHome = function () {
  if (this.x !== this.fx) {
    this.vx += Math.random() * 1.5 - 0.75
    this.x += this.vx
    if (this.x < 0 || this.x > this.mx) {
      this.x = this.fx
    }
  }
  if (this.y !== this.fy) {
    this.vy += Math.random() * 1.5 - 0.75
    this.y += this.vy
    if (this.y < 0 || this.y > this.my) {
      this.y = this.fy
    }
  }
}

Particle.prototype.roamRandomly = function () {
  this.acceleration = 0.95
  this.vx += Math.random() - 0.5
  this.vy += Math.random() - 0.51
  this.x += this.vx
  this.y += this.vy
  this.vx *= this.acceleration
  this.vy *= this.acceleration
  if (this.x < 0) this.x = this.mx
  if (this.x > this.mx) this.x = 0
  if (this.y < 0) this.y = this.my
  if (this.y > this.my) this.y = 0
}

Particle.prototype.goHome = function () {
  this.x = this.fx
  this.y = this.fy
}

export default Particle
