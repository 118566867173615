import { customProperties } from '../variables.js'

const drawAStrokedArc = (context, x, y, r) => {
  context.beginPath()
  context.strokeStyle = customProperties['--color-alpha--40']
  context.lineWidth = 0.5
  context.arc(x, y, r, 0, Math.PI * 2, false)
  context.stroke()
  context.closePath()
}

export default drawAStrokedArc
