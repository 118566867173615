import cx from 'classnames'
import wait from 'delay'
import PropTypes from 'prop-types'
import * as React from 'react'
import Decipher from '../Decipher/Decipher.js'
import Link from '../Link/Link.js'
import Rotator from '../Rotator/Rotator.js'
import Touchable from '../Touchable/Touchable.js'
import * as styles from './About.module.css'

const lines = {
  Hello: "Hello, I'm Pierre Cholhot",
  WhoAmI: 'I am a senior Front-end Developer',
  Love: 'and I ❤ front-end technologies',
  Location: 'I am based in Luxembourg',
  Working: 'and am currently working as a',
  Job: 'freelance front-end developer',
  OwnCompany: 'through my own company',
  Reactive: 'reactive.lu',
  Email: "Let's get in touch",
}

const About = ({ onComplete, className, style, children }) => {
  const [currentLine, setCurrentLine] = React.useState(0)
  const [showAnimations, setShowAnimations] = React.useState(false)
  const [revealEmail, setRevealEmail] = React.useState(false)

  const handleDecypheringComplete = async () => {
    await wait(1000)
    onComplete()
    await wait(1000)
    setShowAnimations(true)
    await wait(3000)
    setRevealEmail(true)
  }

  return (
    <div className={cx(styles.root, styles.flex, className)} style={style}>
      <div className={cx(styles.wrapper, styles.flex)}>
        <Decipher
          wrapper="h1"
          when={currentLine >= 0}
          delay={1000}
          onComplete={() => setCurrentLine(currentLine + 1)}
          message={lines.Hello}
          finalMessage={showAnimations ? <Hello /> : lines.Hello}
        />
        <h2>
          <Decipher
            wrapper="div"
            when={currentLine >= 1}
            delay={1000}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.WhoAmI}
          />
          <Decipher
            wrapper="div"
            when={currentLine >= 2}
            delay={100}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.Love}
            finalMessage={showAnimations ? <Love /> : lines.Love}
          />
        </h2>
        <h2>
          <Decipher
            wrapper="div"
            when={currentLine >= 3}
            delay={1000}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.Location}
            finalMessage={showAnimations ? <Location /> : lines.Location}
            noScriptsMessage={<Location />}
          />
          <Decipher
            wrapper="div"
            when={currentLine >= 4}
            delay={100}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.Working}
          />
          <Decipher
            wrapper="div"
            when={currentLine >= 5}
            delay={100}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.Job}
            finalMessage={showAnimations ? <Job /> : lines.Job}
          />
          <Decipher
            wrapper="div"
            when={currentLine >= 6}
            delay={100}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.OwnCompany}
          />
        </h2>
        <h3>
          <Decipher
            wrapper="div"
            when={currentLine >= 7}
            delay={500}
            onComplete={() => setCurrentLine(currentLine + 1)}
            message={lines.Reactive}
            finalMessage={showAnimations ? <Projects /> : lines.Reactive}
            noScriptsMessage={<Projects />}
          />
        </h3>
        {children}
        <Decipher
          wrapper="h4"
          when={currentLine >= 8}
          delay={750}
          onComplete={handleDecypheringComplete}
          message={lines.Email}
          finalMessage={revealEmail ? <Email /> : lines.Email}
          noScriptsMessage={<Email />}
        />
      </div>
    </div>
  )
}

About.propTypes = {
  onComplete: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

const Hello = () => {
  const hellos = ['Salut', 'Hello', 'ﻣﺮﺣﺒﺎً']
  const cholhots = ['holhot', 'HOLHOT', 'holhot', 'HOLHOT', 'holhot', 'HOLHOT', 'xxxxxx']
  return (
    <React.Fragment>
      <span className={styles.special}>
        <Rotator words={hellos} delay={8000} />
      </span>
      , I'm Pierre C
      <Rotator words={cholhots} delay={2000} />
    </React.Fragment>
  )
}

const Love = () => {
  const loves = ['front-end technologies', 'react.js', 'node.js', 'javascript', 'User eXperience']
  return (
    <React.Fragment>
      and I ❤{' '}
      <span className={styles.special}>
        <Rotator words={loves} delay={2000} />
      </span>
    </React.Fragment>
  )
}

const Job = () => (
  <React.Fragment>
    <span className={styles.special}>freelance</span> front-end developer
  </React.Fragment>
)

const Location = () => (
  <React.Fragment>
    I am based in <span className={styles.location}>Luxembourg</span>
  </React.Fragment>
)

const Projects = () => (
  <React.Fragment>
    <Touchable className={styles.company} external to="https://reactive.lu">
      reactive.lu
    </Touchable>
  </React.Fragment>
)

const Email = () => (
  <Link external to="mailto:hello@pierre.cx">
    hello at pierre.cx
  </Link>
)

export default About
