const dashify = (context, x1, y1, x2, y2) => {
  const dashes = [2, 4]
  const total = dashes.length
  const distanceX = x2 - x1
  const distanceY = y2 - y1
  const slope = distanceY / distanceX

  let remainingDistance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)
  let index = 0
  let draw = true
  let dashLength
  let xStep

  context.moveTo(x1, y1)

  while (remainingDistance >= 0.1) {
    dashLength = dashes[index++ % total]
    if (dashLength > remainingDistance) dashLength = remainingDistance
    xStep = Math.sqrt((dashLength * dashLength) / (1 + slope * slope))
    if (distanceX < 0) xStep = -xStep
    x1 += xStep
    y1 += slope * xStep
    context[draw ? 'lineTo' : 'moveTo'](x1, y1)
    remainingDistance -= dashLength
    draw = !draw
  }
}

export default dashify
