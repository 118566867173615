import cx from 'classnames'
import { debounce } from 'debounce'
import wait from 'delay'
import limitNumber from 'limit-number'
import PropTypes from 'prop-types'
import * as React from 'react'
import withSizes from 'react-sizes'
import drawIntersects from '../../utils/drawIntersects'
import drawSperms from '../../utils/drawSperms'
import extractCircle from '../../utils/extractCircle'
import extractText from '../../utils/extractText'
import { customProperties } from '../../variables.js'
import About from '../About/About.js'
import Social from '../Social/Social.js'
import * as styles from './Stage.module.css'

const initialState = {
  frame: 0,
  injectSocial: false,
  enableIntersections: false,
}

const animationFramesLimit = 10 * 60

class Stage extends React.PureComponent {
  state = initialState

  constructor(props) {
    super(props)
    this.stageCanvas = React.createRef()
    this.extractionCanvas = React.createRef()
    this.debouncedResizeHandler = debounce(this.initializeDrawing, 500)
  }

  componentDidMount() {
    this.initializeDrawing()
  }

  componentDidUpdate = ({ width, height }) => {
    if (width !== this.props.width || height !== this.props.height) {
      window.cancelAnimationFrame(this.drawingLoop)
      this.debouncedResizeHandler.clear()
      this.debouncedResizeHandler.flush()
      this.debouncedResizeHandler()
    }
  }

  initializeDrawing = () => {
    this.reduced = false
    this.circleParticles = []
    this.particles = extractText('pierre.cx', {
      density: 3,
      width: this.props.width,
      height: this.props.height,
      isMobile: this.props.isMobile,
      context: this.extractionCanvas.current.getContext('2d'),
    })
    if (!this.props.isMobile) {
      this.circleParticles = extractCircle(80 / 100, {
        density: 3,
        width: this.props.width,
        height: this.props.height,
        context: this.extractionCanvas.current.getContext('2d'),
      })
    }
    this.drawingLoop = window.requestAnimationFrame(this.draw)
  }

  handleAboutAnimationComplete = () => {
    this.setState({ injectSocial: true }, () => {
      wait(100).then(() => this.setState({ enableIntersections: true }))
    })
  }

  draw = () => {
    this.setState(
      (cs) => ({ frame: cs.frame < animationFramesLimit ? cs.frame + 1 : animationFramesLimit }),
      () => {
        const { width, height } = this.props
        const context = this.stageCanvas.current.getContext('2d')
        context.fillStyle = customProperties['--color-background--20']
        context.fillRect(0, 0, width, height)
        if (this.state.frame < animationFramesLimit) {
          drawSperms(this.particles, { context })
        } else {
          if (!this.reduced) {
            const cloned = [...this.particles]
            const newLength = limitNumber(10, 25, Math.round(width / 100) + 6)
            cloned.length = newLength
            for (let i = 0; i < newLength; i += 1) {
              cloned[i].acceleration = 0.35
              cloned[i].x = this.props.width / 2
              cloned[i].y = this.props.height / 2
            }
            this.particles = cloned
            this.reduced = true
          }
          if (this.state.enableIntersections) {
            drawIntersects(this.particles, {
              context,
              $socialIcons: document.getElementsByClassName('icon-button-pierre-cx'),
              $scrollDoc: document.getElementsByClassName('about-pierre-cx'),
              width: this.props.width,
              isMobile: this.props.isMobile,
            })
          }
        }
        drawSperms(this.circleParticles, { context })
        this.drawingLoop = window.requestAnimationFrame(this.draw)
      }
    )
  }

  render() {
    const { className, style } = this.props
    const showAboutBlock = this.state.frame >= animationFramesLimit
    const socialBlock = this.state.injectSocial ? <Social /> : null

    return (
      <div className={cx(styles.root, className)} style={style}>
        <canvas
          width={this.props.width}
          height={this.props.height}
          ref={this.extractionCanvas}
          className={cx(styles.fullPatateCanvas, styles.helper)}
        />
        <canvas
          width={this.props.width}
          height={this.props.height}
          ref={this.stageCanvas}
          className={cx(styles.fullPatateCanvas, styles.main)}
        />
        {showAboutBlock && (
          <About className="about-pierre-cx" onComplete={this.handleAboutAnimationComplete}>
            {socialBlock}
          </About>
        )}
      </div>
    )
  }
}

Stage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
}

const mapSizesToProps = ({ width, height }) => ({
  width,
  height,
  isMobile: width < 768,
})

export default withSizes(mapSizesToProps)(Stage)
