import cx from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'
import Touchable from '../Touchable/Touchable.js'
import * as styles from './Link.module.css'

const Link = ({ className, style, children, ...rest }) => (
  <Touchable style={style} className={cx(styles.root, className)} data-text={children} {...rest}>
    {children}
  </Touchable>
)

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Link
