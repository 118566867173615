import { customProperties } from '../variables.js'
import dashify from './dashify.js'

const drawParticlesLink = (context, { alpha, bravo, isMobile }) => {
  context.beginPath()
  context.strokeStyle = customProperties['--color-bravo--40']
  if (isMobile) {
    context.moveTo(alpha.x, alpha.y)
    context.lineTo(bravo.x, bravo.y)
  } else {
    dashify(context, alpha.x, alpha.y, bravo.x, bravo.y)
  }
  context.stroke()
  context.closePath()
}

export default drawParticlesLink
