import Particle from './Particle.js'

const getImageParticles = (context, { width, height, density }) => {
  let x, y, pixel
  const particles = []
  const imageData = context.getImageData(0, 0, width, height).data
  for (x = 0; x < width; x += density) {
    for (y = 0; y < height; y += density) {
      pixel = imageData[(x + y * width) * 4 - 1]
      if (pixel === 255) {
        particles.push(new Particle(width, height, x, y))
      }
    }
  }
  return particles
}

export default getImageParticles
