import limit from 'limit-number'
import drawAnArc from './drawAnArc.js'
import drawAStrokedArc from './drawAStrokedArc.js'
import drawParticlesLink from './drawParticlesLink.js'

const drawIntersects = (particles, { context, $socialIcons, $scrollDoc, width, isMobile }) => {
  if (!particles || !$socialIcons || !$socialIcons.length) return

  const maxDist = limit(150, 500, width / 10)
  const totalParticles = particles.length
  const totalIcons = $socialIcons.length
  const iconRadius = 22

  for (let i = 0; i < totalIcons; i += 1) {
    particles[i].linkedParticleIndex = false
    particles[i].vx = 0
    particles[i].vy = 0
    particles[i].x = $socialIcons[i].offsetLeft + iconRadius
    particles[i].y = $socialIcons[i].offsetTop + iconRadius - $scrollDoc[0].scrollTop
    particles[i].radius = iconRadius
  }

  for (let a = totalIcons; a < totalParticles; a += 1) {
    const alpha = particles[a]
    alpha.linkedParticleIndex = false
    alpha.roamRandomly()
    drawAnArc(context, alpha.x, alpha.y)
    drawAStrokedArc(context, alpha.x, alpha.y, alpha.radius)

    for (let b = 0; b < totalParticles; b += 1) {
      const bravo = particles[b]
      bravo.linkedParticleIndex = false
      const dx = alpha.x - bravo.x
      const dy = alpha.y - bravo.y
      const ax = dx / 2000
      const ay = dy / 2000
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance > 0 && distance <= maxDist) {
        if (alpha.linkedParticleIndex !== b && bravo.linkedParticleIndex !== a) {
          alpha.linkedParticleIndex = b
          bravo.linkedParticleIndex = a
          if (b < totalIcons) {
            drawAStrokedArc(context, bravo.x, bravo.y, bravo.radius)
          }
          drawParticlesLink(context, { alpha, bravo, isMobile: isMobile })
        }
        alpha.vx += ax
        alpha.vy += ay
        bravo.vx -= bravo.radius < alpha.radius ? ax * 2 : ax
        bravo.vy -= bravo.radius < alpha.radius ? ay * 2 : ay
      }
    }
  }
}

export default drawIntersects
