const customProperties = {
  '--color-background': 'rgba(0,0,0,1)',
  '--color-background--80': 'rgba(0,0,0,0.8)',
  '--color-background--60': 'rgba(0,0,0,0.6)',
  '--color-background--40': 'rgba(0,0,0,0.4)',
  '--color-background--20': 'rgba(0,0,0,0.2)',

  '--color-foreground': 'rgba(255,255,255,1)',
  '--color-foreground--80': 'rgba(255,255,255,0.8)',
  '--color-foreground--60': 'rgba(255,255,255,0.6)',
  '--color-foreground--40': 'rgba(255,255,255,0.4)',
  '--color-foreground--20': 'rgba(255,255,255,0.2)',

  '--color-alpha': 'rgba(12,187,255,1)',
  '--color-alpha--80': 'rgba(12,187,255,0.8)',
  '--color-alpha--60': 'rgba(12,187,255,0.6)',
  '--color-alpha--40': 'rgba(12,187,255,0.4)',
  '--color-alpha--20': 'rgba(12,187,255,0.2)',

  '--color-bravo': 'rgba(255,78,12,1)',
  '--color-bravo--80': 'rgba(255,78,12,0.8)',
  '--color-bravo--60': 'rgba(255,78,12,0.6)',
  '--color-bravo--40': 'rgba(255,78,12,0.4)',
  '--color-bravo--20': 'rgba(255,78,12,0.2)',

  '--color-charlie': 'rgba(255,255,12,1)',
  '--color-charlie--80': 'rgba(255,255,12,0.8)',
  '--color-charlie--60': 'rgba(255,255,12,0.6)',
  '--color-charlie--40': 'rgba(255,255,12,0.4)',
  '--color-charlie--20': 'rgba(255,255,12,0.2)',

  '--gutter-sm': '6px',
  '--gutter-md': '12px',
  '--gutter-lg': '18px',

  '--fontfamily': '"Space Mono", monospace',
  '--fontweight': 400,
  '--fontsize': '17px',
  '--lineheight': '24px',
}

const customMedia = {
  '--xlarge': '(min-width: 1024px)',
  '--large': '(min-width: 768px)',
  '--medium': '(min-width: 620px)',
}

const customSelectors = {
  ':--headings': 'h1, h2, h3, h4, h5, h6',
  ':--touchables': 'a, button',
}

module.exports = {
  customProperties,
  customMedia,
  customSelectors,
  // environmentVariables: { '--branding-padding': '20px' },
}
