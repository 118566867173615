import { customProperties } from '../variables.js'

const drawAnArc = (context, x, y, r = 1) => {
  context.beginPath()
  context.fillStyle = customProperties['--color-bravo--60']
  context.arc(x, y, r, 0, Math.PI * 2, false)
  context.fill()
  context.closePath()
}

export default drawAnArc
