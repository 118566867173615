import { customProperties } from '../variables.js'

const drawSperms = (particles, { context }) => {
  for (let i = 0, len = particles.length; i < len; i++) {
    particles[i].roamHome()
    const isHome = particles[i].x === particles[i].fx && particles[i].y === particles[i].fy
    context.fillStyle = customProperties[isHome ? '--color-alpha--80' : '--color-alpha--20']
    context.fillRect(particles[i].x - 1, particles[i].y - 1, 2, 2)
  }
}

export default drawSperms
