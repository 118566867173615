import wait from 'delay'

const animate = ({ duration, onAnimationFrame, delay = 0 }) => {
  const animate = (done, start = new Date()) => {
    const tick = () => {
      let progress = (new Date() - start) / duration
      if (progress > 1) progress = 1
      onAnimationFrame(progress)
      if (progress !== 1) return window.requestAnimationFrame(tick)
      return done()
    }
    tick()
  }
  return new Promise((resolve) => {
    wait(delay).then(() => animate(resolve))
  })
}

export default animate
