import React from 'react'
import About from '../About/About.js'
import Social from '../Social/Social.js'

const UnscriptedStage = (props) => (
  <noscript {...props}>
    <link href="https://fonts.googleapis.com/css?family=Space+Mono" rel="stylesheet" />
    <About>
      <Social />
    </About>
  </noscript>
)

UnscriptedStage.propTypes = {}

export default UnscriptedStage
